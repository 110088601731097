import React from "react";

// Customizable Area Start
import {
  Grid,
  Button,
  Box,
  styled
} from '@mui/material';
import { addIcon, locationMarkerRed } from "../../landingpage/src/assets";
import EventScreenController, { configJSON } from "./EventScreenController.web";
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import moment from "moment";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EventsPopUp from "../../../components/src/EventsPopup";
import { ShareEventModal } from "../../../components/src/ShareEventModal";
import EventCalendar from "../../scheduling/src/EventCalendar.web";
import EventMap from "../../maps/src/EventMap.web";
import AdvancedSearches from "../../advancedsearch/src/AdvancedSearches.web";
// Customizable Area End

export default class EventsScreen extends EventScreenController {


  // Customizable Area Start
 
  renderAddButton = () => {
    return (
      <Button fullWidth className="addEventBtn" style={webStyle.shareEvent}>
        Add Your Event
        <Box
          marginLeft={"10px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          style={webStyle.shareEventIcon}
          className="addEventBox"
        >
          <img src={addIcon} height={27} alt="" />
        </Box>
      </Button>
    )
  }

  renderResultSection = () => {
    return (
      <Box sx={webStyle.results} data-test-id="result-count">
        {this.state.eventsLength} results
      </Box>
    )
  }

  renderEventsText = () => {
    return (
      <Box marginTop={"10px"}>
        <Box style={webStyle.eventsFound}>Events found</Box>
        {this.state.calendarView === "day" && (
          <Box
            className="sortByRelevanceDivs"
          >
            Sort By: <Box style={webStyle.sortBy}>Relevance</Box>{" "}
            <Box>
              <KeyboardArrowDownIcon sx={webStyle.relevanceIon} />
            </Box>
          </Box>
        )}
      </Box>
    )
  }

  renderFooterSection = () => {
    return(
      <Box>
        <Grid style={webStyle.footerSection2} container>
          <Box sx={webStyle.footerLink}>Terms of Use</Box>
          <Box fontSize={"24px"}>|</Box>
          <Box data-test-id="linkPrivacy" sx={webStyle.footerLink}>Privacy Policy</Box>
        </Grid>
      </Box>
    )
  }
  displayEvent = (data:any) =>{
    this.setState({isEventsPopupOpen : true, currentEvent:data})
  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    moment.updateLocale("en", {
      week: {
        dow: 1,
      },
    });

    return (
      <MainWrapper>
        <Box bgcolor={"#FAF7F7"}>
          <EventsPopUp open={this.state.isEventsPopupOpen} onClose={this.handleEventPopupClose} onClickShare={this.handleShareModalOpen} event={this.state.currentEvent} />
          <ShareEventModal isOpen={this.state.isShareModalOpen} onClose={this.handleShareModalClose}
            event={this.state.currentEvent?.attributes || {}}
          />
          <AdvancedSearches navigation={undefined} id={""} />
          <Box className="showEventsText">
            {this.renderEventsText()}
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={"8px"}
            sx={{
              flexDirection: {
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
              padding : {
                lg: "30px 10px",
                md: "30px 10px",
                sm: "30px 0px",
                xs: "30px 0px",
              }
            }}
          >
            <Box sx={{
              width: {
                lg: "687px",
                md: "687px",
                sm: "100%",
                xs: "100%"
              }
            }}>
              <EventMap
                id="Map"
                navigation={this.props.navigation}
                eventsMapData={this.state.eventsData.data}
                showEvent={(events) => this.displayEvent(events)}
                data-test-id="showEvent"
              />
            </Box>
            <Box sx={{
              width: {
                lg: "687px",
                md: "687px",
                sm: "100%",
                xs: "100%"
              }
            }} height={"736px"}>
              <Box marginBottom={"19px"} paddingLeft={"24px"}>
                <Box className="rigthEnd" sx={webStyle.sortByDropdownContainer}>
                  <Box className="hideEventsText">
                    {this.renderEventsText()}
                  </Box>
                  <Box>
                    <Box className="hideMobileAddBtn">
                      {this.renderAddButton()}
                    </Box>
                    {this.renderResultSection()}
                  </Box>
                </Box>
              </Box>
              <Box
                height={"659px"}
                width={"100%"}
                bgcolor={"#FFF"}
                borderRadius={"10px"}
              >

                <EventCalendar
                  navigation={this.props.navigation}
                  id={this.props.id}
                  updateResultLength={this.updateEventsResult}
                  updateCalendarType={this.updateCalendarType}
                  data-test-id="calendarComponent"
                />

              </Box>

              <Box className="showMobileAddBtn">
                 {this.renderAddButton()}
              </Box>
            </Box>
          </Box>
          {this.renderFooterSection()}
        </Box>
      </MainWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle: { [key: string]: React.CSSProperties } = {
  footerSection2: {
    background: "#043C61",
    display: "flex",
    justifyContent: "center",
    height: "57px",
    alignContent: "center",
    color: "white",
    gap: "25px",
    alignItems: "baseline",
  },
  footerLink: {
    color: "white",
    display: "flex",
    fontFamily: "Inter",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    fontSize: "18px",
    fontWeight: 400,
  },
  searchContainer: {
    border: "1px solid #888888",
    borderRadius: "100px",
    maxHeight: "50px",
    padding: "6px 6px 6px 13px",
    display: "flex",
    alignItems: "center",
    width: "667px",
  },
  sortByDropdownContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  eventsFound: {
    background:
      "linear-gradient(270deg, #045E81 0%, #3C8D9E 13%, #96B999 27%, #D5E07A 44%, #E9A64D 55%, #F47133 63%, #D62339 83%, #BC3081 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Inter",
    fontSize: "22px",
    fontWeight: "600",
    margin: "0",
  },
  calenderStyle: {
    border: "none",
    width: "100%",
  },
  sortByRelevance: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    marginTop: "0",
  },
  sortBy: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#F47133",
    paddingLeft: "5px",
  },
  shareEvent: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "400",
    paddingLeft: "25px",
    paddingRight: "12px",
    height: "48px",
    borderRadius: "100px",
    margin: "0",
    background: "#D62339",
    color: "white",
    textTransform: "none",
    boxShadow: `-12px -12px 24px 0px #B30117 inset,
        6px 6px 24px 0px #DB001B inset,
        0px 6px 15px 0px #CE041D33`,
  },
  shareEventIcon: {
    height: "34px",
    width: "34px",
    borderRadius: "50%",
    backgroundColor: "white",
  },
  results: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "10px",
    textAlign: "end",
    marginTop: "10px",
    color: "#94A3B8",
  },
  calendarView: {
    fontWeight: "bold",
    color: "#64748B",
    padding: "6px 12px",
    lineHeight: "22px",
    fontFamily: "Inter",
    cursor: "pointer",
    fontSize: "14px",
    border: "1px solid transparent",
  },
  mainContainer: { display: "flex", padding: "20px 60px" },
  mapContainer: {
    flex: 2,
    minHeight: "min(100vh, 736px)",
    maxWidth: "687px",
    maxHeight: "736px",
    borderRadius: "20px",
    overflow: "hidden",
    background: "gray",
  },
  relevanceIon: {
    cursor: "pointer",
    color: "#F47133",
  },
  dropdownContainer: {
    height: "50px",
    borderRadius: "100px",
    color: "#888888",
    border: "1px solid #D9D9D9",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "400",
    backgroundColor: "#FFF",
  },
  formControl: {
    width: 310,
  },
  dropdownIcon: {
    color: "#888888",
  },
  dropdownPaper: {
    maxHeight: 262,
    borderRadius: "5px",
    overflowY: "auto",
  },
  hiddenButton: {
    display: "none",
  },
};

const MainWrapper = styled(Box)({
  "& .hideMobileAddBtn": {
    "@media (max-width: 600px)": {
      display: "none"
    },
  },
  "& .showMobileAddBtn" : {
    display: "none",
    "@media (max-width: 600px)": {
      display: "block",
      margin: "0 30px"
    },
  },
  "& .hideEventsText" : {
    "@media (max-width: 600px)": {
      display: "none"
    },
  },
  "& .showEventsText" : {
    display: "none",
    "@media (max-width: 600px)": {
      display: "block",
      textAlign : "center"
    },
  },
  "& .sortByRelevanceDivs": {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    marginTop: "0",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 600px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
  },
  "& .rigthEnd" : {
    "@media (max-width: 600px)": {
      float : "right"
    },
  },
  "& .addEventBtn" : {
    "@media (max-width: 600px)": {
      position : "relative"
    },
  },
  "& .addEventBox" : {
    "@media (max-width: 600px)": {
      position : "absolute",
      left : 0
    },
  }
})
// Customizable Area End
